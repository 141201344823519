import React from 'react'
import { graphql } from "gatsby"
import Accordion from '../components/accordion';
import { LocaleContext } from "../components/layout"

export default function SchleusenRatgeber(props) {
    const { locale } = React.useContext(LocaleContext)

    const SchleusenData = [
        {
            titleDe: locale === 'de' ? 'Aufwärtsschleusen' : 'Upward locks',
            textDe: locale === 'de' ? props.data.googleSchleusenratgeberSheet.aufwaertsschleusenDe : props.data.googleSchleusenratgeberSheet.aufwaertsschleusenEn
        },
        {
            titleDe: locale === 'de' ? 'Abwärtsschleusen' : 'Downward locks',
            textDe: locale === 'de' ? props.data.googleSchleusenratgeberSheet.abwaertsschleusenDe : props.data.googleSchleusenratgeberSheet.abwaertsschleusenEn
        },
        {
            titleDe: locale === 'de' ? 'Einfahrt in eine Schleuse' : 'Entering a lock',
            textDe: locale === 'de' ? props.data.googleSchleusenratgeberSheet.schleusenEinfahrtDe : props.data.googleSchleusenratgeberSheet.schleusenEinfahrtEn
        },
        {
            titleDe: locale === 'de' ? 'Ausfahrt aus einer Schleuse' : 'Exit from a lock',
            textDe: locale === 'de' ? props.data.googleSchleusenratgeberSheet.schleusenAusfahrtDe : props.data.googleSchleusenratgeberSheet.schleusenAusfahrtEn
        },
        {
            titleDe: locale === 'de' ? 'Wichtige Hinweise' : 'Important notes',
            textDe: locale === 'de' ? props.data.googleSchleusenratgeberSheet.schleusenHinweisDe : props.data.googleSchleusenratgeberSheet.schleusenHinweisEn
        },
        {
            titleDe: locale === 'de' ? 'Manuelle Schleusen' : 'Manual locks',
            textDe: locale === 'de' ? props.data.googleSchleusenratgeberSheet.manuelleSchleusenDe : props.data.googleSchleusenratgeberSheet.manuelleSchleusenEn
        },
        {
            titleDe: locale === 'de' ? 'Elektrische Schleusen' : 'Electric locks',
            textDe: locale === 'de' ? props.data.googleSchleusenratgeberSheet.elektrischeSchleusenDe : props.data.googleSchleusenratgeberSheet.elektrischeSchleusenEn
        },
        {
            titleDe: locale === 'de' ? 'Automatische Schleusen' : 'Automatic locks',
            textDe: locale === 'de' ? props.data.googleSchleusenratgeberSheet.automatischeSchleusenDe : props.data.googleSchleusenratgeberSheet.automatischeSchleusenEn
        },
    ]

    return (
        <>
            <div className="max-w-3xl px-4 mx-auto my-8 md:px-6">
                <h1 className="mb-4 text-xl md:mb-8">{locale === 'de' ? props.data.googleSchleusenratgeberSheet.titleDe : props.data.googleSchleusenratgeberSheet.titleEn}</h1>
                <p className="mb-8 text-lg">{locale === 'de' ? props.data.googleSchleusenratgeberSheet.excerptDe : props.data.googleSchleusenratgeberSheet.excerptEn}</p>

                <dl className="grid max-w-4xl mb-4 gap-y-2 md:mb-12 gap-x-8 lg:order-2">
                    {SchleusenData.map(node => (
                        <Accordion
                            key={node.id}
                            title={node.titleDe}
                            content={node.textDe}
                            id={node.id}
                            open={true}
                        />
                    ))}
                </dl>
            </div>
        </>
    )
}

export const query = graphql`
  query Schleusenratgeber {
    googleSchleusenratgeberSheet {
        id
        titleDe
        subTitleDe
        schleusenHinweisDe
        schleusenEinfahrtDe
        schleusenAusfahrtDe
        manuelleSchleusenDe
        excerptDe
        elektrischeSchleusenDe
        automatischeSchleusenDe
        aufwaertsschleusenDe
        abwaertsschleusenDe
        titleEn
        subTitleEn
        schleusenHinweisEn
        schleusenEinfahrtEn
        schleusenAusfahrtEn
        manuelleSchleusenEn
        excerptEn
        elektrischeSchleusenEn
        automatischeSchleusenEn
        aufwaertsschleusenEn
        abwaertsschleusenEn
    }
  }
`